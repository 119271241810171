import React from 'react'
import ReactPlayer from 'react-player'

import Layout from '../../components/layout'
import LightBox from '../../components/lightbox/lightbox'
import OtherLinks from '../../components/otherLinks/otherLinks'

import p1 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Menü.png'
import p2 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Aufgabenliste.png'
import p3 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Meine Daten.png'
import p4 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Meine Daten Personalien 1.png'
import p5 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Meine Daten Personalien 2.png'
import p6 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Meine Daten Dossier Lohnabrechnungen.png'
import p7 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Meine Daten Dossier Lohnabrechnungen anzeigen.png'
import p8 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Meine Daten Dossier Lohnausweis.png'
import p9 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Meine Mitarbeiter.png'
import p10 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Mitarbeiterliste.png'
import p11 from '../../images/products/HR Mobile/HR Smartphone App AbaClik Mitarbeiterliste Detail.png'

const HRMobile = (props) => {
  return (
    <Layout>
      <section className="section-wrapper">
        <header className="section-header">
          <h3>HR Mobile</h3>
        </header>
        <div className="post">
          <p>
            Die Funktionalität des Abacus HR Portals MyAbacus kann in Teilen auch via Smartphone App verfügbar gemacht werden.
            Gerade für Mitarbeitende, die viel unterwegs sind oder keinen PC verfügbar haben, ist das Arbeiten via Smartphone App sehr interessant.
          </p>
          <LightBox images={overviewImages} thumbnailHeight={90} />
        </div>
        <div className="post section-with-vid" id="video1">
          <div className="vid">
            <ReactPlayer url="https://youtu.be/X1oze50ZgC8" controls={true} height="100%" width="100%" />
          </div>
        </div>

        <OtherLinks links={links} />
      </section>
    </Layout>
  )
}

export default HRMobile

const overviewImages = [
  {
    index: 0,
    src: p1,
    title: 'Hauptmenü',
    description: 'Hauptmenü der Abacus Smartphone App AbaClik.'
  },
  {
    index: 1,
    src: p2,
    title: 'Aufgabenliste',
    description: 'Aufgabenliste der Abacus Smartphone App AbaClik.'
  },
  {
    index: 2,
    src: p3,
    title: 'Meine Daten',
    description: 'Anzeige der eigenen Daten im Personalsystem.'
  },
  {
    index: 3,
    src: p4,
    title: 'Personalien',
    description: 'Anzeige der eigenen Personalien im Personalsystem. Von hier aus können Daten auch geändert werden.'
  },
  {
    index: 4,
    src: p5,
    title: 'Personalien',
    description: 'Anzeige der eigenen Personalien im Personalsystem. Von hier aus können Daten auch geändert werden.'
  },
  {
    index: 5,
    src: p6,
    title: 'Lohnabrechnungen',
    description: 'Lohnabrechnungen können via der Abacus Smartphone App AbaClik angezeigt werden.'
  },
  {
    index: 6,
    src: p7,
    title: 'Lohnabrechnungen',
    description: 'Lohnabrechnungen können via der Abacus Smartphone App AbaClik angezeigt werden.'
  },
  {
    index: 7,
    src: p8,
    title: 'Lohnausweis',
    description: 'Jährliche Lohnausweise können via der Abacus Smartphone App AbaClik angezeigt werden.'
  },
  {
    index: 8,
    src: p9,
    title: 'Daten Ihrer Mitarbeitenden',
    description: 'Als Vorgesetze können Sie Daten Ihrer Mitarbeitenden via der Abacus Smartphone App AbaClik anzeigen.'
  },
  {
    index: 9,
    src: p10,
    title: 'Mitarbeiterliste',
    description: 'Mitarbeiterliste mit öffentlichen Angaben zu den Mitarbeitenden via Abacus Smartphone App AbaClik.'
  },
  {
    index: 10,
    src: p11,
    title: 'Mitarbeiterliste - Detail',
    description: 'Mitarbeiterliste mit öffentlichen Angaben zu den Mitarbeitenden via Abacus Smartphone App AbaClik.'
  },
]

const links = [
  {
    name: 'Abaclik',
    desc: 'Homepage der Abacus Smartphone App AbaClik',
    link: 'https://www.abaclik.ch'
  },
  {
    name: 'Dokumentation',
    desc: 'Dokumentation Funktionalität im HR Bereich',
    link: 'https://www.abaclik.ch/de/features/#ess'
  },
]